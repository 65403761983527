import { Form, Formik, FormikProps } from "formik";
import { Installation } from "../../queries/models/installation.model";
import * as Yup from "yup";
import { EditContentHeader } from "../../components/ui/edit-content-header";
import { AddOrEditInstallation } from "../../queries/models/form-installation.model";
import { useActiveUsersAsSelectOptionQuery } from "../../queries/users.query";
import { useCallback, useMemo, useRef } from "react";
import { useCountriesAsSelectOptionsQuery } from "../../queries/countries.query";
import { useGeolocationFeedbackMutation } from "../../queries/geolocation-query";
import { EditCoordinatesComponent } from "../../components/ui/google-map/edit-coordinates-component";
import FormikDropDown from "../../components/ui/formik/FormikDropdown";
import FormikSlider from "../../components/ui/formik/FormikSlider";
import FormikInputText from "../../components/ui/formik/FormikInputText";
import FormikMultiSelect from "../../components/ui/formik/FormikMultiSelect";
import { Button } from "primereact/button";
import { useToast } from "../../components/ui/toast-context-provider";
import { useWindowSize } from "../../hooks/use-window-size";
import { RoundedShadowContainer } from "../../components/ui/rounded-shadow-container";
import { phoneRegExp } from "../../utils/phoneRegExp";
import { authService } from "../../services/auth.service";
import { AppFeatures } from "../../queries/models/enums/app-feature-enum";
import { LoaderWrapper } from "../../components/ui/LoaderWrapper";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationPin } from "@fortawesome/free-solid-svg-icons";

export interface InstallationFormProps {
  installation: Installation;
  onSave: (form: AddOrEditInstallation) => Promise<any> | void;
  onCancel: () => Promise<any> | void;
  onDelete: () => Promise<any> | void;
  mobileView?: boolean;
}

export function InstallationForm({
  installation,
  onSave,
  onCancel,
  onDelete,
}: InstallationFormProps) {
  const countriesOptionsQuery = useCountriesAsSelectOptionsQuery();
  const activeUsersAsOptionsQuery = useActiveUsersAsSelectOptionQuery();
  const toast = useToast();
  const { lg } = useWindowSize() || {};
  const { t } = useTranslation();
  const hasWriteAccess = useMemo(() => {
    return authService.hasAccess(AppFeatures.WebInstallationsWrite);
  }, []);
  const formRef = useRef<FormikProps<AddOrEditInstallation>>(null);

  const initialValues: AddOrEditInstallation = {
    id: installation?.id ?? 0,
    name: installation?.name ?? "",
    contactName: installation?.contactName ?? "",
    contactPhone: installation?.contactPhone ?? "",
    contactEmail: installation?.contactEmail ?? "",
    address: {
      radius: installation?.address?.radius ?? 50,
      latitude: installation?.address?.latitude,
      longitude: installation?.address?.longitude,
      city: installation?.address?.city ?? "",
      street: installation?.address?.street ?? "",
      postalCode: installation?.address?.postalCode ?? "",
      countryId: installation?.address?.countryId ?? 0,
      id: installation?.addressId ?? 0,
    },
    assignedUserIds: installation?.assignedUserIds ?? [],
  };

  const geolocationMutation = useGeolocationFeedbackMutation();

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(3, t("alert.mustBe3CharactersOrMore"))
      .required(t("alert.required")),
    contactName: Yup.string().min(3, t("alert.mustBe3CharactersOrMore")),
    contactPhone: Yup.string().matches(
      phoneRegExp,
      t("alert.phoneNumberFormatInvalid")
    ),
    contactEmail: Yup.string().email(t("alert.contactEmailMustBeValidEmail")),
    address: Yup.object()
      .shape({
        countryId: Yup.number()
          .min(1, t("alert.minimum1CountryMustBeSelected"))
          .required(t("alert.required")),
        city: Yup.string()
          .required(t("alert.required"))
          .min(3, t("alert.mustBe3CharactersOrMore")),
        street: Yup.string()
          .required(t("alert.required"))
          .min(1, t("alert.mustBe1CharacterOrMore")),
        postalCode: Yup.string().required(t("alert.required")),
        radius: Yup.number().min(10).max(500).default(50).required(),
        latitude: Yup.number().required(t("alert.required")),
        longitude: Yup.number().required(t("alert.required")),
      })
      .required(t("alert.required")),
    assignedUserIds: Yup.array(),
  });

  const onSubmit = useCallback(
    (values: AddOrEditInstallation) => {
      if (!hasWriteAccess) {
        return;
      }
      return onSave(values);
    },
    [hasWriteAccess, onSave]
  );

  const handleGetCoordinates = useCallback(() => {
    geolocationMutation
      .mutateAsync({
        country:
          countriesOptionsQuery.data?.find(
            (x) => x.value === formRef.current?.values.address.countryId
          )?.label ?? "",
        city: formRef.current?.values.address.city ?? "",
        street: formRef.current?.values.address.street ?? "",
      })
      .then(
        (result) => {
          formRef.current?.setValues((x) => ({
            ...x,
            address: {
              ...x.address,
              city: result.city ?? "",
              street: result.street ?? x.address?.street ?? "",
              latitude: result.latitude ?? "",
              longitude: result.longitude ?? "",
              postalCode: result.postalCode ?? "",
            },
          }));

          toast.current?.show({
            severity: "success",
            detail: `Found "${result.readableAddress}"`,
          });
        },
        (err) => {
          toast.current?.show({
            severity: "error",
            detail: typeof err?.data === "string" && err.data,
          });
        }
      );
  }, [countriesOptionsQuery.data, geolocationMutation, toast]);

  return (
    <RoundedShadowContainer
      small
      fullHeight
      mobile={lg}
    >
      <LoaderWrapper
        isLoading={
          countriesOptionsQuery.isLoading || activeUsersAsOptionsQuery.isLoading
        }
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnChange
          validateOnMount
          innerRef={formRef}
        >
          {(formik) => (
            <Form className="w-full h-full">
              <div className="h-full flex flex-column">
                <div className="p-2 h-3rem">
                  <EditContentHeader
                    header={
                      installation?.id
                        ? installation.name
                        : t("common.addNewInstallation")
                    }
                    showDeleteButton={hasWriteAccess && !!installation.id}
                    saveButtonDisabled={
                      !hasWriteAccess || !formik.isValid || !formik.dirty
                    }
                    onSaveClick={async () => {
                      if (!formik.isValid) {
                        toast.current?.show({
                          severity: "error",
                          detail: t("alert.formInvalid"),
                        });
                        return;
                      }
                      return formik.submitForm();
                    }}
                    onCancelClick={onCancel}
                    onDeleteClick={onDelete}
                  />
                </div>
                <div
                  className="p-2 overflow-x-hidden flex flex-column"
                  style={{ height: "calc(100% - 3rem)" }}
                >
                  <div
                    className="formgrid grid"
                    style={{ flex: "0" }}
                  >
                    <div className="field col-12 md:col-6">
                      <FormikInputText
                        label={t("common.name")}
                        name="name"
                        validationSchema={validationSchema}
                        isIndependent
                        disabled={!hasWriteAccess}
                      />
                    </div>
                    <div className="field col-12 md:col-6">
                      <FormikInputText
                        label={t("common.contactName")}
                        name="contactName"
                        isIndependent
                        disabled={!hasWriteAccess}
                      />
                    </div>
                    <div className="field col-12 md:col-6">
                      <FormikInputText
                        label={t("common.email")}
                        name="contactEmail"
                        validationSchema={validationSchema}
                        isIndependent
                        disabled={!hasWriteAccess}
                      />
                    </div>
                    <div className="field col-12 md:col-6">
                      <FormikInputText
                        label={t("common.contactPhone")}
                        name="contactPhone"
                        validationSchema={validationSchema}
                        isIndependent
                        disabled={!hasWriteAccess}
                      />
                    </div>
                    <div className="w-12">
                      <div className="field col-12 md:col-6">
                        <FormikMultiSelect
                          label={t("common.selectUsers")}
                          name="assignedUserIds"
                          validationSchema={validationSchema}
                          options={activeUsersAsOptionsQuery.data}
                          showFilter
                          disabled={!hasWriteAccess}
                        />
                      </div>
                    </div>
                    <div className="field col-12 md:col-6">
                      <FormikDropDown
                        label={t("common.country")}
                        options={countriesOptionsQuery.data}
                        name="address.countryId"
                        validationSchema={validationSchema}
                        disabled={!hasWriteAccess}
                      />
                    </div>
                    <div className="field col-12 md:col-6">
                      <FormikInputText
                        label={t("common.city")}
                        name="address.city"
                        validationSchema={validationSchema}
                        isIndependent
                        disabled={!hasWriteAccess}
                      />
                    </div>
                    <div className="field col-12 md:col-6">
                      <FormikInputText
                        label={t("common.street")}
                        name="address.street"
                        validationSchema={validationSchema}
                        isIndependent
                        disabled={!hasWriteAccess}
                      />
                    </div>
                    <div className="field col-12 md:col-6">
                      <FormikInputText
                        label={t("common.postalCode")}
                        name="address.postalCode"
                        validationSchema={validationSchema}
                        isIndependent
                        disabled={!hasWriteAccess}
                      />
                    </div>
                    <div className="field col-12 md:col-9">
                      <FormikSlider
                        label={t("common.radius")}
                        name="address.radius"
                        validationSchema={validationSchema}
                        nested
                        isIndependent
                        step={10}
                        disabled={!hasWriteAccess}
                      />
                    </div>
                    <div className="field col-12 md:col-3">
                      <div className="flex gap-2">
                        <Button
                          className="green-action-button my-2 w-full"
                          icon={<FontAwesomeIcon icon={faLocationPin} />}
                          style={{
                            height: "39px",
                            outline:
                              (formik.errors.address?.latitude ||
                                formik.errors.address?.longitude) &&
                              "1px solid red",
                          }}
                          type="button"
                          label={t("common.getCoordinates")}
                          disabled={
                            !hasWriteAccess ||
                            (formik.values.address.city &&
                            formik.values.address.countryId &&
                            formik.values.address.street
                              ? false
                              : true)
                          }
                          loading={geolocationMutation.isLoading}
                          onClick={handleGetCoordinates}
                        />
                        <div className="text-red-500 font-bold py-1 text-xl">
                          *
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={lg ? "" : "h-15rem"}
                    style={{
                      flex: "1",
                      minHeight: "200px",
                      outline:
                        (formik.errors.address?.latitude ||
                          formik.errors.address?.longitude) &&
                        "1px solid red",
                    }}
                  >
                    <EditCoordinatesComponent
                      latitude={formik.values.address.latitude}
                      longitude={formik.values.address.longitude}
                      radius={formik.values.address.radius}
                      onCoordinatesChanged={(e: google.maps.MapMouseEvent) => {
                        formik.setValues((x) => ({
                          ...x,
                          address: {
                            ...x.address,
                            longitude: e.latLng?.lng(),
                            latitude: e.latLng?.lat(),
                          },
                        }));
                      }}
                      zoom={12}
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </LoaderWrapper>
    </RoundedShadowContainer>
  );
}
