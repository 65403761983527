import { UserMonthlyOrdersOverviewItem } from "../../queries/models/user-monthly-orders-overview.model";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { OrderSynchronizationStatus } from "../../queries/models/enums/order-synchronization-status.enum";
import { Calendar } from "primereact/calendar";
import { TableHeader } from "../../components/ui/table-header";
import { useCallback } from "react";
import { ordersValidatorService } from "../../services/orders-validator.service";
import { lightFormat } from "date-fns";
import { useTranslation } from "react-i18next";

export interface OverviewSectionComponentProps {
  items: UserMonthlyOrdersOverviewItem[];
  date: Date;
  selectedItem: UserMonthlyOrdersOverviewItem | undefined;
  onDateChange: (v: Date) => void;
  onSelectItem: (v: UserMonthlyOrdersOverviewItem | undefined) => void;
}

export function OverviewSectionComponent({
  items,
  date,
  selectedItem,
  onDateChange,
  onSelectItem,
}: OverviewSectionComponentProps) {
  const { t } = useTranslation();
  const handleDownloadExcel = useCallback(async () => {
    const result = await ordersValidatorService.getMonthlyExportData(date);

    import("xlsx").then((xlsx) => {
      const ws_header = [
        "Id",
        "Installator",
        "Installator (full name)",
        "Phone",
        "Email",
        "Installation",
        "Work type",
        "Start date",
        "End date",
        "Working time",
        "Quantity",
        "Status",
        "Odoo ID",
        "Odoo order URL",
      ];
      const ws_rows = result.map((x) => [
        x.orderId,
        x.installatorName,
        x.installatorFullName,
        x.userPhone,
        x.email,
        x.installationName,
        x.workType,
        x.workStartDate,
        x.workEndDate,
        x.workingTime,
        x.quantity,
        x.status,
        x.odooId,
        x.odooOrderUrl,
      ]);
      const ws_data = [ws_header, ...ws_rows];

      const worksheet = xlsx.utils.aoa_to_sheet(ws_data);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "purple-orders");
    });
  }, [date]);

  const saveAsExcelFile = (buffer: any, fileName: string) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName +
            "_export_" +
            lightFormat(new Date(), "yyyy-MM-dd_HH-mm") +
            EXCEL_EXTENSION
        );
      }
    });
  };

  return (
    <div className="h-full flex flex-column">
      <div className="p-2">
        <Calendar
          className="w-full"
          view="month"
          value={date}
          dateFormat="MM yy"
          onChange={(e) => onDateChange(new Date(e.value as string))}
        />
      </div>
      {items && (
        <DataTable
          style={{ height: "calc(100% - 4rem)" }}
          scrollable
          scrollHeight="flex"
          value={items}
          dataKey="userId"
          header={
            <TableHeader
              header={t("common.usersWork")}
              showButton={true}
              buttonLabel={t("common.export")}
              buttonIcon="pi pi-download"
              onClick={handleDownloadExcel}
            />
          }
          selectionMode="single"
          selection={selectedItem}
          onSelectionChange={(e) =>
            onSelectItem(e.value as UserMonthlyOrdersOverviewItem | undefined)
          }
          emptyMessage={t("common.noResultsFound")}
        >
          <Column
            field="username"
            header={t("common.name")}
            sortable
            body={(x) => `${x.username} (${x.ordersCount})`}
            filterMatchMode="contains"
            showFilterMenu={false}
          />
          <Column
            field="synchronizationStatus"
            header={t("common.status")}
            body={(x) => OrderSynchronizationStatus[x.synchronizationStatus]}
            sortable
          />
        </DataTable>
      )}
    </div>
  );
}
