import { useState, useCallback, useMemo, BaseSyntheticEvent } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnFilterElementTemplateOptions } from "primereact/column";
import { useGetAllAssetsQuery } from "../../queries/assets.query";
import { RoundedShadowContainer } from "../../components/ui/rounded-shadow-container";
import { TableHeader } from "../../components/ui/table-header";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "../../hooks/use-window-size";
import { Asset } from "../../queries/models/asset.model";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Tag } from "../../queries/models/tag.model";
import { SelectItem } from "primereact/selectitem";
import { useActiveTagsQuery } from "../../queries/tags.query";
import { TagItem } from "../../components/ui/tags/tag-item";
import { FilterMatchMode } from "primereact/api";
import { format } from "date-fns";
import { Calendar } from "primereact/calendar";
import { isWithinInterval } from "date-fns";
import { useWarehousesAsSelectOptionsQuery } from "../../queries/warehouses.query";
import { LoaderWrapper } from "../../components/ui/LoaderWrapper";
import { authService } from "../../services/auth.service";
import { AppFeatures } from "../../queries/models/enums/app-feature-enum";
import { useAssetTypesQuery } from "../../queries/asset-type.query";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";

export function AssetsPage() {
  const { t } = useTranslation();
  const { lg } = useWindowSize();
  const navigate = useNavigate();
  const assetsQuery = useGetAllAssetsQuery();
  const warehousesOptionsQuery = useWarehousesAsSelectOptionsQuery();
  const assetTypesQuery = useAssetTypesQuery();

  const tagsQuery = useActiveTagsQuery();
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    "installation.name": { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const hasWriteAccess = useMemo(
    () => authService.hasAccess(AppFeatures.WebAssetStickerWrite),
    []
  );

  const onGlobalFilterChange = useCallback(
    (e: BaseSyntheticEvent) => {
      let _filters = { ...filters };

      const value = e.target.value;

      _filters["global"].value = value;
      setFilters(_filters);
      setGlobalFilterValue(value);
    },
    [filters]
  );

  const tagOptions = useMemo(
    () =>
      tagsQuery.data?.map((x) => ({
        label: x.name,
        value: x.id,
        extraValue: x,
      })),
    [tagsQuery.data]
  );

  const assetTypeOptions = useMemo(
    () =>
      assetTypesQuery.data
        ?.filter((x) => !x.isArchived)
        .map((x) => ({
          label: x.name,
          value: x.id,
          extraValue: x,
        })),
    [assetTypesQuery.data]
  );

  const warehouseOptions = useMemo(
    () =>
      warehousesOptionsQuery.data?.map((x) => ({
        label: x.label,
        value: x.value,
        extraValue: x,
      })),
    [warehousesOptionsQuery.data]
  );

  const maintenanceOptions: SelectItem[] = useMemo(
    () => [
      { value: true, label: t("common.yes") },
      { value: false, label: t("common.no") },
    ],
    [t]
  );

  const selectedTagTemplate = useCallback(
    (id: number) => {
      var tag = tagsQuery.data?.find((x) => x.id === id);
      return tag ? (
        <TagItem
          tagName={tag?.name}
          textColor={tag?.textColor}
          backGroundColor={tag?.backgroundColor}
        />
      ) : (
        <div className="inline-block"></div>
      );
    },
    [tagsQuery.data]
  );

  const tagFilterElement = useCallback(
    (options: ColumnFilterElementTemplateOptions) => (
      <MultiSelect
        className="w-12"
        maxSelectedLabels={1}
        value={options.value}
        options={tagOptions}
        showClear
        style={{ maxHeight: "37px", alignItems: "center" }}
        onChange={(e) => {
          options.filterCallback(e.value, options.index);
          options.filterApplyCallback(e.value, options.index);
          e.originalEvent.stopPropagation();
        }}
        selectedItemTemplate={selectedTagTemplate}
        placeholder={t("common.any")}
      />
    ),
    [selectedTagTemplate, t, tagOptions]
  );
  const selectedAssetTypeTemplate = useCallback(
    (id: number) => {
      var tag = assetTypesQuery.data?.find((x) => x.id === id);
      return tag ? (
        <TagItem
          tagName={tag?.name}
          textColor={tag?.textColor}
          backGroundColor={tag?.backgroundColor}
        />
      ) : (
        <div className="inline-block"></div>
      );
    },
    [assetTypesQuery.data]
  );

  const assetTypeFilterElement = useCallback(
    (options: ColumnFilterElementTemplateOptions) => (
      <MultiSelect
        className="w-12"
        maxSelectedLabels={1}
        value={options.value}
        options={assetTypeOptions}
        showClear
        style={{ maxHeight: "37px", alignItems: "center" }}
        onChange={(e) => {
          options.filterCallback(e.value, options.index);
          options.filterApplyCallback(e.value, options.index);
          e.originalEvent.stopPropagation();
        }}
        selectedItemTemplate={selectedAssetTypeTemplate}
        placeholder={t("common.any")}
      />
    ),
    [assetTypeOptions, selectedAssetTypeTemplate, t]
  );

  const warehouseFilterElement = useCallback(
    (options: ColumnFilterElementTemplateOptions) => (
      <Dropdown
        value={options.value}
        options={warehouseOptions}
        showClear
        onChange={(e) => {
          options.filterCallback(e.value, options.index);
          options.filterApplyCallback(e.value, options.index);
        }}
        placeholder={t("common.any")}
      />
    ),
    [t, warehouseOptions]
  );

  const maintenanceFilterElement = useCallback(
    (options: ColumnFilterElementTemplateOptions) => (
      <Dropdown
        options={maintenanceOptions}
        placeholder={t("common.any")}
        value={options.value}
        showClear
        onChange={(e) => {
          options.filterCallback(e.value, options.index);
          options.filterApplyCallback(e.value, options.index);
        }}
      />
    ),
    [t, maintenanceOptions]
  );

  const dateRowFilterTemplate = useCallback(
    (options: ColumnFilterElementTemplateOptions) => (
      <Calendar
        selectionMode="range"
        dateFormat="dd/mm/yy"
        value={options.value}
        onChange={(e) => {
          options.filterCallback(e.value, options.index);
          options.filterApplyCallback(e.value, options.index);
        }}
        locale="en"
        placeholder={t("common.date")}
        showButtonBar
      />
    ),
    [t]
  );

  const dateBodyTemplate = (rowData: any, field: string) => {
    var value = rowData[field];
    return <>{value ? format(value, "dd/LL/yyyy") : null}</>;
  };

  const tagsBodyTemplate = (rowData: Asset) => {
    return (
      <div className="flex flex-wrap">
        {rowData.tags.map((x, i) => (
          <TagItem
            key={i}
            tagName={x.name}
            textColor={x.textColor}
            backGroundColor={x.backgroundColor}
          />
        ))}
      </div>
    );
  };

  const assetTypesBodyTemplate = (rowData: Asset) => {
    const assetType = assetTypesQuery.data?.find(
      (x) => x.id === rowData.assetTypeId
    );

    if (!assetType) return <></>;

    return (
      <div className="flex flex-wrap">
        <TagItem
          tagName={assetType.name}
          textColor={assetType.textColor}
          backGroundColor={assetType.backgroundColor}
        />
      </div>
    );
  };

  const maintenanceBodyTemplate = useCallback(
    (isMaintenanceRequired: boolean) => {
      return (
        <div>{isMaintenanceRequired ? t("common.yes") : t("common.no")}</div>
      );
    },
    [t]
  );

  return (
    <div className="h-full w-full p-1">
      <RoundedShadowContainer
        medium
        fullHeight
        mobile={lg}
      >
        <LoaderWrapper isLoading={assetsQuery.isLoading || tagsQuery.isLoading}>
          <div className="flex flex-column h-full w-full">
            {!lg && (
              <div className="py-1 px-2 h-3rem">
                <InputText
                  value={globalFilterValue}
                  onChange={onGlobalFilterChange}
                  className="w-full"
                  placeholder={t("common.filter")}
                />
              </div>
            )}
            <div
              className="flex flex-column w-full"
              style={{ height: !lg ? "calc(100% - 3rem)" : "100%" }}
            >
              <DataTable
                scrollable
                scrollHeight="flex"
                className="border-round-xl w-full table-cell-ellipsis"
                filters={!lg ? filters : undefined}
                globalFilterFields={["name", "installation.name"]}
                header={
                  <TableHeader
                    header={t("common.assets")}
                    showButton
                    buttonLabel={t("common.addAsset")}
                    onClick={() => navigate("details")}
                    rightContent={() => (
                      <Button
                        label={t("common.editTypes")}
                        icon="pi pi-pencil"
                        onClick={() => navigate("/app/asset-types")}
                      />
                    )}
                    buttonDisabled={!hasWriteAccess}
                  />
                }
                selectionMode="single"
                onSelectionChange={(e) =>
                  navigate(`details/${(e.value as Asset).id}`)
                }
                value={assetsQuery.data}
                filterDisplay="row"
                emptyMessage={t("common.noResultsFound")}
              >
                <Column
                  header={
                    <span className="cell-elipsis">{t("common.name")}</span>
                  }
                  field="name"
                  filter
                  showFilterMenu={false}
                  sortable
                  filterPlaceholder={t("common.filter")}
                  filterMatchMode="contains"
                  style={{ width: "calc(20%)" }}
                />
                <Column
                  header={
                    <span className="cell-elipsis">
                      {t("common.maintenance")}
                    </span>
                  }
                  field="isMaintenanceRequired"
                  filter
                  filterMatchMode="equals"
                  filterElement={maintenanceFilterElement}
                  showFilterMenu={false}
                  sortable
                  hidden={!lg}
                  style={{ width: "calc(5%)" }}
                  body={(x) => maintenanceBodyTemplate(x.isMaintenanceRequired)}
                />
                <Column
                  header={
                    <span className="cell-elipsis">
                      {t("common.installation")}
                    </span>
                  }
                  field="installation.name"
                  filter
                  filterMatchMode="contains"
                  showFilterMenu={false}
                  sortable
                  filterPlaceholder={t("common.filter")}
                  style={{ width: "calc(20%)" }}
                />
                <Column
                  header={
                    <span className="cell-elipsis">{t("common.type")}</span>
                  }
                  field="assetTypeId"
                  filter
                  filterMatchMode="custom"
                  filterField="assetTypeId"
                  filterFunction={(val: any[], filter: any[]) => {
                    if (!filter || filter?.length === 0) return true;
                    if (!val || val?.length === 0) return false;

                    if (filter.some((x) => x === val)) {
                      return true;
                    }
                    return false;
                  }}
                  showFilterMenu={false}
                  filterElement={assetTypeFilterElement}
                  body={(e) => assetTypesBodyTemplate(e)}
                  hidden={!lg}
                  style={{ width: "calc(10%)" }}
                />
                <Column
                  header={
                    <span className="cell-elipsis">
                      {t("common.nextFollowUp")}
                    </span>
                  }
                  field="nextFollowupDate"
                  filter
                  filterMatchMode="custom"
                  filterFunction={(
                    value: Date | null | undefined,
                    filter: Date[]
                  ) => {
                    if (
                      !filter ||
                      filter.length !== 2 ||
                      filter[0] === null ||
                      filter[1] === null
                    ) {
                      return true;
                    }
                    const [startDate, endDate] = filter;
                    if (value === null || value === undefined) {
                      return false;
                    }
                    const startOfDay = new Date(startDate).setHours(0, 0, 0, 0);
                    const endOfDay = new Date(endDate).setHours(
                      23,
                      59,
                      59,
                      999
                    );

                    return isWithinInterval(value, {
                      start: startOfDay,
                      end: endOfDay,
                    });
                  }}
                  body={(e) => dateBodyTemplate(e, "nextFollowupDate")}
                  showFilterMenu={false}
                  sortable
                  filterElement={dateRowFilterTemplate}
                  hidden={!lg}
                  style={{ width: "calc(10%)" }}
                />
                <Column
                  header={
                    <span className="cell-elipsis">
                      {t("common.warehouse")}
                    </span>
                  }
                  field="warehouse.name"
                  showFilterMenu={false}
                  sortable
                  filterElement={warehouseFilterElement}
                  filter
                  filterMatchMode="custom"
                  filterField="warehouse"
                  filterPlaceholder={t("common.filter")}
                  filterFunction={(val: any, filter: SelectItem) => {
                    if (!filter) return true;
                    if (!val) return false;
                    if (val.id === filter) return true;
                    return false;
                  }}
                  hidden={!lg}
                  style={{ width: "calc(10%)" }}
                />
                <Column
                  header={
                    <span className="cell-elipsis">
                      {t("common.serialNumber")}
                    </span>
                  }
                  field="serialNumber"
                  filter
                  showFilterMenu={false}
                  sortable
                  filterPlaceholder={t("common.filter")}
                  hidden={!lg}
                  style={{ width: "calc(10%)" }}
                />
                <Column
                  header={
                    <span className="cell-elipsis">{t("common.tags")}</span>
                  }
                  field="tags"
                  filter
                  filterMatchMode="custom"
                  filterField="tags"
                  filterFunction={(val: Tag[], filter: SelectItem[]) => {
                    if (!filter || filter?.length === 0) return true;
                    if (!val || val?.length === 0) return false;

                    if (filter.some((x) => val.some((y) => y.id === x))) {
                      return true;
                    }
                    return false;
                  }}
                  showFilterMenu={false}
                  filterElement={tagFilterElement}
                  body={(e) => tagsBodyTemplate(e)}
                  hidden={!lg}
                  style={{ width: "calc(10%)" }}
                />
              </DataTable>
            </div>
          </div>
        </LoaderWrapper>
      </RoundedShadowContainer>
    </div>
  );
}
