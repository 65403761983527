import {
  BaseSyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import { FilterMatchMode } from "primereact/api";
import { format, subDays } from "date-fns";
import { RoundedShadowContainer } from "../../components/ui/rounded-shadow-container";
import { TableHeaderWithDateRangeFilter } from "../../components/ui/table-header-with-date-range-filter";
import { useActiveWorkInstancesQuery } from "../../queries/work-instances.query";
import { useWindowSize } from "../../hooks/use-window-size";
import { WorkInstance } from "../../queries/models/work-instance.model";
import { WorkInstanceState } from "../../queries/models/enums/work-instance-state.enum";
import { SpaceBeforeCapital } from "../../utils/space-before-capital";
import { EnumExtensions } from "../../utils/enum-extensions";
import { WorkInstanceType } from "../../queries/models/enums/work-instance-type.enum";
import Enumerable from "linq";
import { LoaderWrapper } from "../../components/ui/LoaderWrapper";
import {
  asDataTableFilterOptions,
  useDataTableLocalFilters,
} from "../../hooks/useDataTableLocalFilters";
import {
  CustomFilterMatchMode,
  dateParser,
} from "../../utils/dataTableCustomFilters";
import { useTranslation } from "react-i18next";
import { useDeleteWorkInstanceMutation } from "../../queries/work-instances.query";
import { useToast } from "../../components/ui/toast-context-provider";
import { useQueryClient } from "react-query";

const WorkInstancesDataTableFilters = asDataTableFilterOptions({
  useUrlParams: true,
  dataTableSchema: {
    global: {
      matchMode: FilterMatchMode.CONTAINS,
      isGlobalFieldWithDefaultValue: "",
    },
    "user.username": {
      matchMode: FilterMatchMode.CONTAINS,
    },
    "installation.name": {
      matchMode: FilterMatchMode.CONTAINS,
    },
    state: {
      matchMode: FilterMatchMode.IN,
    },
    type: {
      matchMode: FilterMatchMode.IN,
    },
    datePlanned: {
      matchMode: CustomFilterMatchMode.dateRange,
      parse: dateParser,
    },
    dateStarted: {
      matchMode: CustomFilterMatchMode.dateRange,
      parse: dateParser,
    },
    dateEnded: {
      matchMode: CustomFilterMatchMode.dateRange,
      parse: dateParser,
    },
    dateRange: {
      external: true,
      parse: dateParser,
    },
  },
});

export function WorkInstancesOverview() {
  const { t } = useTranslation();
  const { lg } = useWindowSize() || {};
  const toast = useToast();
  const queryClient = useQueryClient();

  const workStateOptions = useMemo(
    () => EnumExtensions.getLabelAndValues(WorkInstanceState),
    []
  );
  const workInstanceTypeOptions = useMemo(
    () => EnumExtensions.getLabelAndValues(WorkInstanceType),
    []
  );
  const [selectedWorkInstance, setSelectedWorkInstance] = useState<
    WorkInstance | undefined
  >(undefined);

  const {
    dataTableFilters,
    onDataTableFilter,
    getFilterValue,
    setFilter,
    dataTableSortField,
    dataTableSortOrder,
    onDataTableSort,
  } = useDataTableLocalFilters(WorkInstancesDataTableFilters);

  const globalFilterValue: string | undefined = useMemo(
    () => getFilterValue("global"),
    [getFilterValue]
  );
  const dateRange: (Date | null)[] = useMemo(
    () => getFilterValue("dateRange") ?? [subDays(new Date(), 14), new Date()],
    [getFilterValue]
  );
  const setDateRange = useCallback(
    (newRange: Date[]) => {
      setFilter("dateRange", {
        value: newRange,
        matchMode: FilterMatchMode.BETWEEN,
      });
    },
    [setFilter]
  );

  const workInstancesQuery = useActiveWorkInstancesQuery(
    dateRange[0],
    dateRange[1]
  );
  const deleteWorkInstanceMutation = useDeleteWorkInstanceMutation();
  const navigate = useNavigate();

  const dateRowFilterTemplate = useCallback(
    (options: ColumnFilterElementTemplateOptions) => (
      <Calendar
        selectionMode="range"
        dateFormat="dd/mm/yy"
        value={options.value}
        onChange={(e) => {
          options.filterApplyCallback(e.value, options.index);
        }}
        placeholder={t("common.date")}
        showButtonBar
        locale="en"
      />
    ),
    [t]
  );

  const workInstanceStateRowFilterTemplate = useCallback(
    (options: ColumnFilterElementTemplateOptions) => (
      <MultiSelect
        options={workStateOptions}
        value={options.value}
        onChange={(e) => {
          options.filterApplyCallback(e.value, options.index);
          e.originalEvent.stopPropagation();
        }}
        placeholder={t("common.any")}
        maxSelectedLabels={1}
      />
    ),
    [t, workStateOptions]
  );

  const workInstanceTypeRowFilterTemplate = useCallback(
    (options: ColumnFilterElementTemplateOptions) => (
      <MultiSelect
        options={workInstanceTypeOptions}
        value={options.value}
        onChange={(e) => {
          options.filterApplyCallback(e.value, options.index);
          e.originalEvent.stopPropagation();
        }}
        placeholder={t("common.any")}
        maxSelectedLabels={1}
      />
    ),
    [t, workInstanceTypeOptions]
  );

  const onGlobalFilterChange = useCallback(
    (e: BaseSyntheticEvent) => {
      setFilter("global", {
        value: e.target.value,
        matchMode: FilterMatchMode.CONTAINS,
      });
    },
    [setFilter]
  );

  useEffect(() => {
    if (selectedWorkInstance) {
      navigate(`details/${selectedWorkInstance.id}`);
    }
  }, [navigate, selectedWorkInstance]);

  const sortedWorkInstances = useMemo(() => {
    const customOrder = [
      WorkInstanceState.Active,
      WorkInstanceState.Finished,
      WorkInstanceState.Pending,
      WorkInstanceState.Unspecified,
    ];

    return Enumerable.from(workInstancesQuery.data ?? [])
      .orderBy((x) => customOrder.indexOf(x.state))
      .thenByDescending((x) => x.dateStarted ?? x.datePlanned)
      .toArray();
  }, [workInstancesQuery.data]);

  return (
    <div
      className="p-1"
      style={{ height: lg ? "100%" : "calc(100% - 3rem)" }}
    >
      <RoundedShadowContainer
        medium
        fullHeight
        mobile={lg}
      >
        <div className="flex flex-column h-full w-full relative">
          {!lg && (
            <div className="h-3rem p-1">
              <InputText
                className="w-full"
                value={globalFilterValue}
                onChange={onGlobalFilterChange}
              />
            </div>
          )}
          <LoaderWrapper isLoading={workInstancesQuery.isLoading}>
            <DataTable
              value={sortedWorkInstances}
              scrollable
              scrollHeight="flex"
              className="w-full"
              resizableColumns={true}
              globalFilterFields={["user.username", "installation.name"]}
              filterDisplay={lg ? "row" : undefined}
              filters={dataTableFilters}
              onFilter={onDataTableFilter}
              header={
                <TableHeaderWithDateRangeFilter
                  header={t("common.workInstances")}
                  value={dateRange}
                  onFilterValueChange={setDateRange}
                  onClearClick={() => {
                    setDateRange([subDays(new Date(), 14), new Date()]);
                  }}
                  showCalendarBottomBar
                />
              }
              selectionMode="single"
              selection={selectedWorkInstance}
              onSelectionChange={(e) => {
                setSelectedWorkInstance(e.value as WorkInstance);
              }}
              sortField={dataTableSortField}
              sortOrder={dataTableSortOrder}
              onSort={onDataTableSort}
              emptyMessage={t("common.noResultsFound")}
            >
              <Column
                header={t("common.username")}
                field="user.username"
                filter
                filterMatchMode="contains"
                showFilterMenu={false}
                sortable
                filterPlaceholder={t("common.filter")}
                style={{
                  width: lg ? "15%" : "30%",
                }}
              />
              <Column
                header={t("common.installationName")}
                field="installation.name"
                filter
                filterMatchMode="contains"
                showFilterMenu={false}
                sortable
                filterPlaceholder={t("common.filter")}
                style={{
                  width: lg ? "30%" : "70%",
                }}
                className="white-space-normal"
              />
              <Column
                header={t("common.state")}
                field="state"
                body={(x) => (
                  <span className={x.hasInvalidWorkHours ? "text-red-600" : ""}>
                    {SpaceBeforeCapital.transform(WorkInstanceState[x.state])}
                  </span>
                )}
                sortable
                filter
                filterMatchMode="in"
                filterElement={workInstanceStateRowFilterTemplate}
                hidden={!lg}
                showFilterMenu={false}
                style={{
                  width: "12%",
                }}
              />
              <Column
                header={t("common.workType")}
                field="type"
                body={(x) =>
                  SpaceBeforeCapital.transform(WorkInstanceType[x.type])
                }
                sortable
                filter
                filterMatchMode="in"
                filterElement={workInstanceTypeRowFilterTemplate}
                hidden={!lg}
                showFilterMenu={false}
              />
              <Column
                header={t("common.datePlanned")}
                field="datePlanned"
                dataType="date"
                body={(x) =>
                  x.datePlanned ? format(x.datePlanned, "dd/LL/yyyy") : "-"
                }
                filter
                showFilterMenu={false}
                filterMatchMode="custom"
                filterElement={dateRowFilterTemplate}
                sortable
                hidden={!lg}
                style={{ width: "15%" }}
              />
              <Column
                header={t("common.dateStarted")}
                field="dateStarted"
                dataType="date"
                body={(x) =>
                  x.dateStarted ? format(x.dateStarted, "dd/LL/yyyy") : "-"
                }
                filter
                showFilterMenu={false}
                filterMatchMode="custom"
                filterElement={dateRowFilterTemplate}
                sortable
                hidden={!lg}
                style={{
                  width: "15%",
                }}
              />
              <Column
                header={t("common.dateEnded")}
                field="dateEnded"
                dataType="date"
                body={(x) =>
                  x.dateEnded ? format(x.dateEnded, "dd/LL/yyyy") : "-"
                }
                filter
                showFilterMenu={false}
                filterMatchMode="custom"
                filterElement={dateRowFilterTemplate}
                sortable
                hidden={!lg}
                style={{
                  width: "15%",
                }}
              />
            </DataTable>
          </LoaderWrapper>
        </div>
      </RoundedShadowContainer>
    </div>
  );
}
