import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useState, useCallback, useMemo, BaseSyntheticEvent } from "react";
import { Role } from "../../../queries/models/role-model";
import {
  useAddRoleMutation,
  useDeleteRoleMutation,
  useEditRoleMutation,
  useRolesQuery,
} from "../../../queries/roles.query";
import { useQueryClient } from "react-query";
import { useToast } from "../../../components/ui/toast-context-provider";
import { AddOrEditRole } from "../../../queries/models/add-edit-role";
import { CreateRoleRequest } from "../../../queries/models/create-role-request";
import { UpdateRoleRequest } from "../../../queries/models/update-role-request";
import {
  CustomModal,
  CustomModalProps,
} from "../../../components/ui/MobileModal/custom-modal";
import { RoundedShadowContainer } from "../../../components/ui/rounded-shadow-container";
import { LoaderWrapper } from "../../../components/ui/LoaderWrapper";
import { TableHeader } from "../../../components/ui/table-header";
import { RolesForm } from "../RolesForm";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { useTranslation } from "react-i18next";

export function MobileRolesPage() {
  const { t } = useTranslation();
  const [selectedRole, setSelectedRole] = useState<Role>();
  const rolesQuery = useRolesQuery();
  const deleteRoleMutation = useDeleteRoleMutation();
  const addRoleMutation = useAddRoleMutation();
  const editRoleMutation = useEditRoleMutation();
  const queryClient = useQueryClient();
  const toast = useToast();
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState<boolean>(false);
  const [isDetailsPageModalOpen, setIsDetailsPageModalOpen] =
    useState<boolean>(false);

  const handleAddNewRole = () => {
    setSelectedRole({
      name: "",
    } as Role);
  };
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const saveNewRole = useCallback(
    (data: AddOrEditRole) => {
      const mutateOptions = {
        onSuccess: async () => {
          toast.current?.show({
            severity: "success",
            detail: "Save success",
          });
          await queryClient.invalidateQueries("roles");
          setIsDetailsPageModalOpen(false);
          setSelectedRole(undefined);
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
      };

      const request: CreateRoleRequest = {
        name: data.name,
        description: data.description,
        appFeatures: data.appFeatures ?? [],
      };

      return addRoleMutation.mutateAsync(request, mutateOptions);
    },
    [addRoleMutation, queryClient, toast]
  );

  const editExistingRole = useCallback(
    (data: AddOrEditRole) => {
      const mutateOptions = {
        onSuccess: async () => {
          toast.current?.show({
            severity: "success",
            detail: "Edit success",
          });
          await queryClient.invalidateQueries("roles");
          setIsDetailsPageModalOpen(false);
          setSelectedRole(undefined);
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
      };

      const request: UpdateRoleRequest = {
        id: data.id,
        name: data.name,
        description: data.description,
        appFeatures: data.appFeatures ?? [],
      };

      return editRoleMutation.mutateAsync(request, mutateOptions);
    },
    [editRoleMutation, queryClient, toast]
  );

  const handleSaveRole = useCallback(
    (data: AddOrEditRole) => {
      return data.id === 0 ? saveNewRole(data) : editExistingRole(data);
    },
    [editExistingRole, saveNewRole]
  );

  const handleDeleteRole = useCallback(() => {
    if (selectedRole?.id) {
      deleteRoleMutation.mutateAsync(selectedRole.id, {
        onSuccess: async () => {
          await queryClient.invalidateQueries("roles");
          toast.current?.show({
            severity: "success",
            detail: "Role successfully deleted",
          });
          setIsDetailsPageModalOpen(false);
          setSelectedRole(undefined);
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
      });
      setIsDeleteConfirmationModalOpen(false);
    }
  }, [deleteRoleMutation, selectedRole, toast, queryClient]);

  const onGlobalFilterChange = useCallback(
    (e: BaseSyntheticEvent) => {
      let _filters = { ...filters };

      const value = e.target.value;
      _filters["global"].value = value;
      setFilters(_filters);
      setGlobalFilterValue(value);
    },
    [filters]
  );

  const deleteConfirmationModalProps: CustomModalProps = useMemo(() => {
    return {
      header: "Confirmation",
      body: "Delete this role?",
      height: "160px",
      isOpen: isDeleteConfirmationModalOpen,
      confirmation: true,
      centered: true,
      justified: true,
      onClose: () => setIsDeleteConfirmationModalOpen(false),
      onConfirm: () => handleDeleteRole(),
      zIndex: "3",
    };
  }, [handleDeleteRole, isDeleteConfirmationModalOpen]);

  const selectedRoleModal: CustomModalProps = {
    isOpen: isDetailsPageModalOpen,
    onClose: () => {
      setIsDetailsPageModalOpen(false);
      setSelectedRole(undefined);
    },
    header: selectedRole?.name ?? "",
    justified: true,
    confirmation: false,
    height: "h-full",
    body: selectedRole && (
      <RolesForm
        role={selectedRole}
        onSave={handleSaveRole}
        onCancel={() => {
          setSelectedRole(undefined);
          setIsDetailsPageModalOpen(false);
        }}
        onDelete={() => setIsDeleteConfirmationModalOpen(true)}
      />
    ),
    zIndex: "2",
  };

  return (
    <div className="p-1 h-full">
      <CustomModal {...deleteConfirmationModalProps} />
      <CustomModal {...selectedRoleModal} />
      <RoundedShadowContainer
        small
        fullHeight
        mobile={false}
      >
        <LoaderWrapper isLoading={rolesQuery.isLoading}>
          <div className="h-3rem py-1 px-2">
            <InputText
              placeholder="Filter"
              className="w-full"
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
            />
          </div>
          <DataTable
            style={{ height: "calc(100% - 3rem)" }}
            scrollable={true}
            scrollHeight="flex"
            value={rolesQuery.data}
            header={
              <TableHeader
                header="Roles"
                showButton={true}
                onClick={() => {
                  handleAddNewRole();
                  setIsDetailsPageModalOpen(true);
                }}
              />
            }
            selectionMode="single"
            resizableColumns={false}
            selection={selectedRole}
            onSelectionChange={(e) => {
              setSelectedRole(e.value as Role);
              setIsDetailsPageModalOpen(true);
            }}
            globalFilterFields={["name", "description"]}
            filters={filters}
            emptyMessage={t("common.noResultsFound")}
          >
            <Column
              field="name"
              header="Name"
              className="w-3"
              sortable
            />
            <Column
              field="description"
              header="Description"
              className="w-3"
              sortable
            />
          </DataTable>
        </LoaderWrapper>
      </RoundedShadowContainer>
    </div>
  );
}
