import { useCallback, useMemo, useState, BaseSyntheticEvent } from "react";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useActiveInstallationsQuery } from "../../queries/installations.query";
import { TableHeader } from "../../components/ui/table-header";
import { useWindowSize } from "../../hooks/use-window-size";
import { Installation } from "../../queries/models/installation.model";
import { RoundedShadowContainer } from "../../components/ui/rounded-shadow-container";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { LoaderWrapper } from "../../components/ui/LoaderWrapper";
import { authService } from "../../services/auth.service";
import { AppFeatures } from "../../queries/models/enums/app-feature-enum";
import { useTranslation } from "react-i18next";

export function InstallationsOverview() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { lg } = useWindowSize() || {};
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    "address.city": { value: null, matchMode: FilterMatchMode.CONTAINS },
    "address.street": { value: null, matchMode: FilterMatchMode.CONTAINS },
    "address.postalCode": { value: null, matchMode: FilterMatchMode.CONTAINS },
    contactName: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const handleGlobalFilterChange = useCallback(
    (e: BaseSyntheticEvent) => {
      const value = e.target.value;
      let _filters = filters;
      _filters.global.value = value;
      setFilters(_filters);
      setGlobalFilterValue(value);
    },
    [filters]
  );

  const handleAddNewInstallation = useCallback(() => {
    navigate("details");
  }, [navigate]);

  const handleEditInstallation = useCallback(
    (installation: Installation) => {
      navigate(`details/${installation.id}`);
    },
    [navigate]
  );

  const installationsQuery = useActiveInstallationsQuery();

  const hasWriteAccess = useMemo(
    () => authService.hasAccess(AppFeatures.WebInstallationsWrite),
    []
  );

  return (
    <div className="h-full py-1 px-1">
      <RoundedShadowContainer
        small
        fullHeight
        mobile={lg}
      >
        {!lg && (
          <div className="p-1 h-3rem">
            <InputText
              className="w-full"
              value={globalFilterValue}
              onChange={handleGlobalFilterChange}
            />
          </div>
        )}
        <div
          style={{ height: lg ? "100%" : "calc(100% - 3rem)" }}
          className="relative"
        >
          <LoaderWrapper isLoading={installationsQuery.isLoading}>
            <DataTable
              className="h-full "
              value={
                installationsQuery.isFetched ? installationsQuery.data : []
              }
              header={
                <TableHeader
                  header={t("common.installations")}
                  showButton={true}
                  onClick={handleAddNewInstallation}
                  buttonDisabled={!hasWriteAccess}
                  buttonLabel={t("common.add")}
                />
              }
              filters={filters}
              globalFilterFields={["name", "address.city"]}
              scrollHeight="flex"
              scrollable
              resizableColumns={false}
              selectionMode="single"
              onSelectionChange={(e) =>
                handleEditInstallation(e.value as Installation)
              }
              filterDisplay={lg ? "row" : undefined}
              emptyMessage={t("common.noResultsFound")}
            >
              <Column
                header={t("common.name")}
                field="name"
                sortable
                filter
                showFilterMenu={false}
                filterPlaceholder={t("common.filter")}
                className={lg ? "cell-elipsis" : ""}
                style={{
                  width: lg ? "20vw" : "",
                }}
              />
              <Column
                header={t("common.city")}
                field="address.city"
                sortable
                filter
                showFilterMenu={false}
                filterPlaceholder={t("common.filter")}
                style={{ width: lg ? "30vw" : "" }}
              />
              <Column
                header={t("common.street")}
                field="address.street"
                sortable
                filter
                showFilterMenu={false}
                filterPlaceholder={t("common.filter")}
                hidden={!lg}
                style={{ width: "20vw" }}
              />
              <Column
                header={t("common.postalCode")}
                field="address.postalCode"
                sortable
                filter
                showFilterMenu={false}
                filterPlaceholder={t("common.filter")}
                hidden={!lg}
                style={{ width: "10vw" }}
              />
              <Column
                header={t("common.contactName")}
                field="contactName"
                sortable
                filter
                showFilterMenu={false}
                filterPlaceholder={t("common.filter")}
                hidden={!lg}
                style={{ width: "20vw" }}
              />
            </DataTable>
          </LoaderWrapper>
        </div>
      </RoundedShadowContainer>
    </div>
  );
}
