import { useState, useCallback, useMemo } from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import {
  useLanguagesOptionsQuery,
  useSelectedLanguagesQuery,
  useSelectSingleLanguageMutation,
} from "../../queries/languages.query";
import { SelectItem } from "primereact/selectitem";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { useQueryClient } from "react-query";
import { useToast } from "../../components/ui/toast-context-provider";
import { Tooltip } from "primereact/tooltip";
import { Language } from "../../queries/models/language.model";
import RoundedDot from "../../components/ui/RoundedDot";
import { LoaderWrapper } from "../../components/ui/LoaderWrapper";
import { useTranslation } from "react-i18next";

interface LanguagesOverviewProps {
  hasWriteAccess?: boolean;
  onSelectedLanguageChange: (language: Language) => void;
}

export function LanguagesOverview({
  hasWriteAccess = false,
  onSelectedLanguageChange,
}: LanguagesOverviewProps) {
  const { t } = useTranslation();
  const [selectedLanguageOption, setSelectedLanguageOption] = useState<
    SelectItem | undefined
  >();
  const toast = useToast();
  const queryClient = useQueryClient();
  const languagesOptionsQuery = useLanguagesOptionsQuery();
  const selectedLanguagesQuery = useSelectedLanguagesQuery();
  const selectSingleLanguageMutation = useSelectSingleLanguageMutation();

  const statusOptions: SelectItem[] = useMemo(
    () => [
      { value: true, label: t("common.published") },
      { value: false, label: t("common.unpublished") },
    ],
    [t]
  );

  const handleSelectSingleLanguage = useCallback(() => {
    if (selectedLanguageOption) {
      const language = languagesOptionsQuery?.data?.find(
        (x) => x.value === selectedLanguageOption?.value
      );
      confirmDialog({
        message: `${t("dialog.doYouWantToSelect")} ${language?.label}`,
        header: t("common.confirmation"),
        icon: "pi pi-exclamation-triangle text-yellow-600",
        accept: () => {
          const mutateOptions = {
            onSuccess: async () => {
              toast.current?.show({
                severity: "success",
                detail: t("alert.selectSuccess"),
              });
              setSelectedLanguageOption(undefined);
              await queryClient.invalidateQueries("selected-languages");
            },
            onError: async (error: any) => {
              toast.current?.show({
                severity: "error",
                detail: error?.data,
              });
            },
          };
          selectSingleLanguageMutation.mutateAsync(
            selectedLanguageOption.value,
            mutateOptions
          );
        },
      });
    }
  }, [
    queryClient,
    selectSingleLanguageMutation,
    selectedLanguageOption,
    t,
    toast,
    languagesOptionsQuery.data,
  ]);

  return (
    <>
      <div className="h-full relative">
        <LoaderWrapper
          isLoading={
            languagesOptionsQuery.isLoading ||
            selectSingleLanguageMutation.isLoading
          }
        >
          <div className="flex p-2 justify-content-between gap-2">
            <Dropdown
              key={selectedLanguageOption?.value}
              className="w-9"
              value={selectedLanguageOption?.value}
              onChange={(e) =>
                e.value
                  ? setSelectedLanguageOption({
                      value: e.target.value,
                      label: e.target.label,
                    })
                  : setSelectedLanguageOption(undefined)
              }
              options={languagesOptionsQuery.data}
              filter
              showClear
              disabled={!hasWriteAccess}
            />
            <Button
              className="w-3"
              icon="pi pi-plus"
              label={t("common.add")}
              disabled={!selectedLanguageOption}
              onClick={handleSelectSingleLanguage}
            />
          </div>
          <div className="pt-1">
            <Tooltip
              target=".status-cell"
              hideDelay={300}
            />
            <DataTable
              value={selectedLanguagesQuery.data}
              key="id"
              filterDisplay="row"
              selectionMode="single"
              onSelectionChange={(e) =>
                onSelectedLanguageChange(e.value as Language)
              }
              resizableColumns={false}
              scrollable={true}
              emptyMessage={t("common.noResultsFound")}
            >
              <Column
                header={t("common.language")}
                field="name"
                filter
                showFilterMenu={false}
                filterMatchMode="contains"
                filterPlaceholder={t("common.filter")}
                sortable
              />
              <Column
                header={t("common.status")}
                body={(x) =>
                  x.isPublished ? (
                    <div
                      className="status-cell"
                      data-pr-tooltip={t("common.publishedLanguage")}
                    >
                      <RoundedDot color="#4CAF50" />
                    </div>
                  ) : (
                    <div
                      className="status-cell"
                      data-pr-tooltip={t("common.unpublishedLanguage")}
                    >
                      <RoundedDot color="#BDBDBD" />
                    </div>
                  )
                }
                filter
                showFilterMenu={false}
                filterMatchMode="contains"
                filterField="isPublished"
                filterElement={(options) => (
                  <Dropdown
                    options={statusOptions}
                    value={options.value}
                    onChange={(e) => {
                      options.filterCallback(e.value, options.index);
                      options.filterApplyCallback(e.value, options.index);
                    }}
                    showClear
                  />
                )}
              />
            </DataTable>
          </div>
        </LoaderWrapper>
      </div>
    </>
  );
}
