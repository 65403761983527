import { useCallback, useState, useMemo } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { WorkEvent } from "../../queries/models/work-event.model";
import { WorkEventType } from "../../queries/models/enums/work-event-type.enum";
import { SpaceBeforeCapital } from "../../utils/space-before-capital";
import {
  CustomModal,
  CustomModalProps,
} from "../../components/ui/MobileModal/custom-modal";
import format from "date-fns/format";
import { useWindowSize } from "../../hooks/use-window-size";
import { useWorkEventQuery } from "../../queries/work-events.query";
import { ImageWrapper } from "../../components/ui/ImageWrapper";
import { GalleryPreview } from "../../components/ui/GalleryPreview/gallery-preview";
import { TabView, TabPanel } from "primereact/tabview";
import { EmptyContentBanner } from "../../components/ui/EmptyContentBanner";
import { FileDownload } from "../../components/ui/FileDownload";
import { LoaderWrapper } from "../../components/ui/LoaderWrapper";
import { useTranslation } from "react-i18next";

interface WorkInstanceEventOverviewTableProps {
  items: WorkEvent[];
}

export function WorkInstanceEventsOverview({
  items,
}: WorkInstanceEventOverviewTableProps) {
  const { t } = useTranslation();
  const { lg } = useWindowSize();
  const [selectedWorkEventId, setSelectedWorkEventId] = useState<
    number | undefined
  >(undefined);
  const [activeIndex, setActiveIndex] = useState<number>();

  const workEventQuery = useWorkEventQuery(selectedWorkEventId);
  const formatWorkEventType = useCallback((x: WorkEvent) => {
    const forced =
      x.workInstance.userId !== x.userId && x.type === WorkEventType.WorkEnded;
    return (
      <div
        className={forced ? "text-red-500" : ""}
      >{`${SpaceBeforeCapital.transform(WorkEventType[x.type])} ${
        forced ? "(forced)" : ""
      }`}</div>
    );
  }, []);

  const formatWorkEventDate = useCallback(
    (x: WorkEvent) => format(x.date, "dd/LL/yyyy HH:mm"),
    []
  );

  const formatWorkEventAction = useCallback(
    (x: WorkEvent) => (
      <Button
        label={t("common.details")}
        className="green-action-button"
        onClick={() => setSelectedWorkEventId(x.id)}
      />
    ),
    [t]
  );

  const workEventDetailModalProps: CustomModalProps = useMemo(
    () => ({
      isOpen: !!selectedWorkEventId,
      header: t("common.workEventDetails"),
      onClose: () => {
        setSelectedWorkEventId(undefined);
        setActiveIndex(undefined);
      },
      width: lg ? "40%" : "95%",
      height: lg ? "60%" : "",
      centered: true,
      justified: true,
      body: (
        <div className="h-full overflow-hidden">
          <LoaderWrapper isLoading={workEventQuery.isLoading}>
            <div className="flex flex-column h-full ">
              <div
                className="w-full"
                style={{
                  flex: "0",
                }}
              >
                <div className="grid m-0">
                  <div className="col-4 text-right text-500 text-xl">
                    {t("common.date")}:
                  </div>
                  <div className="col-8 text-xl">
                    {workEventQuery.data
                      ? format(workEventQuery.data.date, "dd/LL/yyyy")
                      : ""}
                  </div>
                  <div className="col-4 text-right text-500 text-xl">
                    {t("common.type")}:
                  </div>
                  <div className="col-8 text-xl">
                    {workEventQuery.data
                      ? SpaceBeforeCapital.transform(
                          WorkEventType[workEventQuery.data.type]
                        )
                      : ""}
                  </div>
                  {workEventQuery.data?.asset && (
                    <>
                      <div className="col-4 text-right text-500 text-xl">
                        {t("common.name")}:
                      </div>
                      <div className="col-8 text-xl">
                        {workEventQuery.data.asset.name}
                      </div>
                    </>
                  )}
                  <div className="col-4 text-right text-500 text-xl">
                    {t("common.comment")}:
                  </div>
                  <div className="col-8 text-xl">
                    {workEventQuery.data
                      ? workEventQuery.data.comment ?? "-"
                      : ""}
                  </div>
                  {workEventQuery.data
                    ? workEventQuery.data?.images?.length !== 0 && (
                        <>
                          <div className="col-4 text-right text-500 text-xl">
                            {t("common.photo")}:
                          </div>
                          <div className="col-8 text-xl">{`x${workEventQuery.data.images.length}`}</div>
                        </>
                      )
                    : ""}
                </div>
              </div>

              {workEventQuery.data ? (
                workEventQuery.data.images?.length ||
                workEventQuery.data.files?.length ? (
                  <div
                    style={{
                      height: "100%",
                    }}
                    className="overflow-auto"
                  >
                    <TabView className="h-full">
                      <TabPanel
                        header={t("common.images")}
                        className="h-full"
                      >
                        {workEventQuery.data.images.length ? (
                          <div className="p-1">
                            {workEventQuery.data.images.map((x, i) => (
                              <div
                                key={i}
                                style={{ display: "inline-block" }}
                                onClick={() => setActiveIndex(i)}
                              >
                                <ImageWrapper
                                  imageSrc={x.thumbnailSrc}
                                  width="100%"
                                  height="100%"
                                />
                              </div>
                            ))}
                          </div>
                        ) : (
                          <EmptyContentBanner text={t("common.NO_IMAGES")} />
                        )}
                      </TabPanel>
                      <TabPanel
                        header={t("common.files")}
                        className="h-full"
                      >
                        {workEventQuery.data.files.length ? (
                          <div className="p-1">
                            {workEventQuery.data.files.map((x, i) => (
                              <FileDownload
                                key={i}
                                file={x}
                              />
                            ))}
                          </div>
                        ) : (
                          <EmptyContentBanner text={t("common.NO_FILES")} />
                        )}
                      </TabPanel>
                    </TabView>
                  </div>
                ) : (
                  <div></div>
                )
              ) : (
                ""
              )}
            </div>
          </LoaderWrapper>
        </div>
      ),
    }),
    [lg, selectedWorkEventId, t, workEventQuery.data, workEventQuery.isLoading]
  );

  return (
    <>
      <CustomModal {...workEventDetailModalProps} />
      {workEventQuery.data?.images && activeIndex !== undefined && (
        <GalleryPreview
          images={workEventQuery.data.images}
          activeIndex={activeIndex}
          onItemChange={(e) => setActiveIndex(e)}
          onClose={() => setActiveIndex(undefined)}
        />
      )}
      <div className="h-full">
        <DataTable
          header={t("common.workEvents")}
          scrollable
          className="z-0"
          scrollHeight={lg ? "100%" : "23rem"}
          value={items}
          emptyMessage={t("common.noResultsFound")}
        >
          <Column
            header={t("common.date")}
            field="date"
            dataType="date"
            sortable
            body={formatWorkEventDate}
          />
          <Column
            header={t("common.user")}
            field="user.username"
            sortable
          />
          <Column
            header={t("common.type")}
            field="type"
            body={formatWorkEventType}
            sortable
          />

          <Column
            header={t("common.action")}
            body={formatWorkEventAction}
          />
        </DataTable>
      </div>
    </>
  );
}
