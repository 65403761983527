import { useEffect } from "react";
import { DataTable } from "primereact/datatable";
import {
  useDashboardDetailQuery,
  useDashboardOverviewQuery,
  useForceFinishWorkMutation,
} from "../../queries/dashboard.query";
import { Column } from "primereact/column";
import { useState, useCallback } from "react";
import { DashboardOverviewItem } from "../../queries/models/dashboard-overview-item.model";
import { differenceInMilliseconds, format } from "date-fns";
import { TableHeader } from "../../components/ui/table-header";
import { WorkDurationState } from "../../queries/models/enums/work-duration-state.enum";
import { TimeSpan } from "../../utils/timespan";
import { MarkerModel } from "../../components/ui/google-map/marker-model";
import { MapWithMarkers } from "../../components/ui/google-map/map-with-markers";
import { WorkTimeChartComponent } from "../../components/ui/WorkTimeChartComponent";
import { LocationLog } from "../../queries/models/location-log.model";
import { RoundedShadowContainer } from "../../components/ui/rounded-shadow-container";
import {
  CustomModal,
  CustomModalProps,
} from "../../components/ui/MobileModal/custom-modal";
import { ForceFinishWorkForm } from "./ForceFinishWorkForm";
import { ForceFinishWorkRequest } from "../../queries/models/force-finish-work-request.model";
import { BaseSyntheticEvent } from "react";
import { confirmPopup } from "primereact/confirmpopup";
import { useQueryClient } from "react-query";
import { useToast } from "../../components/ui/toast-context-provider";
import { SelectedWorkDetails } from "./SelectedWorkDetails";
import { LoaderWrapper } from "../../components/ui/LoaderWrapper";
import { useTranslation } from "react-i18next";

export function Dashboard() {
  const { t } = useTranslation();
  const toast = useToast();
  const queryClient = useQueryClient();
  const workOverviewQuery = useDashboardOverviewQuery(60 * 1000);
  const forceFinishWorkMutation = useForceFinishWorkMutation();
  const [
    selectedWorkInstanceToForceFinishId,
    setSelectedWorkInstanceToFinishId,
  ] = useState<number | undefined>(undefined);
  const [selectedWorkOverview, setSelectedWorkOverview] =
    useState<DashboardOverviewItem>();
  const [isFinishWorkModalOpen, setIsFinishWorkModalOpen] =
    useState<boolean>(false);
  const [isFinishWorkModalOpenFaded, setIsFinishWorkModalOpenFaded] =
    useState<boolean>(false);
  const [hoveredPosition, setHoveredPosition] = useState<LocationLog>();
  const [mapMarkers, setMapMarkers] = useState<MarkerModel[]>([]);

  const dashboardDetailQuery = useDashboardDetailQuery(
    selectedWorkOverview?.workInstanceId
  );

  useEffect(() => {
    if (selectedWorkOverview && dashboardDetailQuery.data) {
      setMapMarkers([
        {
          latitude: dashboardDetailQuery.data.lastLocation?.latitude ?? 0,
          longitude: dashboardDetailQuery.data.lastLocation?.longitude ?? 0,
          showInfoWindow: true,
          title: dashboardDetailQuery.data.workInstance.user?.username!,
          markerIconUrl:
            "http://maps.google.com/mapfiles/kml/paddle/red-circle.png",
          iconSize: 45,
          infoWindowBody: (
            <div>
              <div>
                {dashboardDetailQuery.data.workInstance.installation?.name}
              </div>
              <div>{`${dashboardDetailQuery.data.workInstance.installation?.address?.city}, ${dashboardDetailQuery.data.workInstance.installation?.address?.street}`}</div>
            </div>
          ),
        },
        {
          latitude:
            dashboardDetailQuery.data.workInstance.installation.address
              .latitude ?? 0,
          longitude:
            dashboardDetailQuery.data.workInstance.installation.address
              .longitude ?? 0,
          showInfoWindow: false,
          title: dashboardDetailQuery.data.workInstance.installation?.name,
          markerIconUrl:
            "http://maps.google.com/mapfiles/kml/shapes/ranger_station.png",
          radius:
            dashboardDetailQuery.data.workInstance.installation.address.radius,
          iconSize: 30,
          infoWindowBody: (
            <div>
              <div className="text-base font-medium">{`${dashboardDetailQuery.data.workInstance.installation?.name}`}</div>
              <div className="text-base">{`${dashboardDetailQuery.data.workInstance.installation?.address?.street}, ${dashboardDetailQuery.data.workInstance.installation?.address?.postalCode}`}</div>
            </div>
          ),
        },
      ]);
    } else if (workOverviewQuery.data) {
      const mapMarkers: MarkerModel[] = [];
      workOverviewQuery.data?.forEach((x) => {
        mapMarkers.push(
          {
            latitude: x.lastLocation.latitude,
            longitude: x.lastLocation.longitude,
            showInfoWindow: true,
            title: x.username,
            infoWindowBody: <></>,
            markerIconUrl:
              "http://maps.google.com/mapfiles/kml/paddle/red-circle.png",
            iconSize: 45,
          },
          {
            latitude: x.installationLatitude,
            longitude: x.installationLongitude,
            showInfoWindow: false,
            title: x.installationName,
            infoWindowBody: <></>,
            markerIconUrl:
              "http://maps.google.com/mapfiles/kml/shapes/ranger_station.png",
            iconSize: 30,
          }
        );
      });
      setMapMarkers(mapMarkers);
    }
  }, [dashboardDetailQuery.data, selectedWorkOverview, workOverviewQuery.data]);

  const showFinishWorkModal = (workInstanceId: number) => {
    setSelectedWorkInstanceToFinishId(workInstanceId);
    setIsFinishWorkModalOpen(true);
  };

  const handleFinishWork = useCallback(
    (request: ForceFinishWorkRequest, event: BaseSyntheticEvent) => {
      confirmPopup({
        target: event.target,
        icon: "pi pi-question-circle",
        message: "Do you really want to finish work manually?",
        reject: () => {
          setSelectedWorkInstanceToFinishId(undefined);
          setIsFinishWorkModalOpen(false);
        },
        accept: () => {
          const mutateOptions = {
            onSuccess: async () => {
              await queryClient.invalidateQueries("dashboardOverview");
              toast.current?.show({
                severity: "success",
                detail: "Work instance finished successfully",
              });
              setSelectedWorkInstanceToFinishId(undefined);
              setSelectedWorkOverview(undefined);
              setIsFinishWorkModalOpen(false);
            },
            onError: async (error: any) => {
              toast.current?.show({
                severity: "error",
                detail: error?.data,
              });
            },
          };

          forceFinishWorkMutation.mutateAsync(request, mutateOptions);
        },
      });
    },
    [forceFinishWorkMutation, queryClient, toast]
  );

  const onAnimatedCloseFinishWorkModal = useCallback(() => {
    setIsFinishWorkModalOpenFaded(true);
    setTimeout(() => {
      setIsFinishWorkModalOpen(false);
      setSelectedWorkInstanceToFinishId(undefined);
      setIsFinishWorkModalOpenFaded(false);
    }, 400);
  }, []);

  const forceFinishWorkModalProps: CustomModalProps = {
    header: t("common.confirmation"),
    isOpen: isFinishWorkModalOpen,
    isModalForceFaded: isFinishWorkModalOpenFaded,
    body: (
      <ForceFinishWorkForm
        workInstanceId={selectedWorkInstanceToForceFinishId ?? 0}
        onSave={handleFinishWork}
        onCancel={onAnimatedCloseFinishWorkModal}
      />
    ),
    height: "max-content",
    centered: true,
    justified: true,
    onClose: () => {
      setIsFinishWorkModalOpen(false);
      setSelectedWorkInstanceToFinishId(undefined);
    },
  };

  const getCurrentDurationString = function (x: DashboardOverviewItem): any {
    const state = WorkDurationState[x.workDurationState];
    const now = new Date();
    let duration = TimeSpan.fromMiliseconds(0);
    if (x.dateLastDuration !== undefined && x.dateLastDuration <= now) {
      duration = TimeSpan.fromMiliseconds(
        differenceInMilliseconds(new Date(), new Date(x.dateLastDuration!))
      );
    }

    return `${state} (${duration.toHhMm()})`;
  };

  const lastActivityBodyItemTemplate = useCallback(
    (item: DashboardOverviewItem) => {
      const difference = +new Date() - +new Date(item.lastLocation.date);
      const ts = new TimeSpan(difference * 10_000);
      return ts.toTimeAgo(t);
    },
    [t]
  );

  return (
    <div className="flex gap-2 h-full p-1">
      <CustomModal {...forceFinishWorkModalProps} />
      <div className="w-6 h-full">
        <RoundedShadowContainer
          small
          fullHeight
        >
          <LoaderWrapper isLoading={workOverviewQuery.isLoading}>
            <DataTable
              className="h-full border-round-xl"
              metaKeySelection={false}
              scrollHeight="flex"
              scrollable
              value={workOverviewQuery.isFetched ? workOverviewQuery.data : []}
              dataKey="workInstanceId"
              header={
                <TableHeader
                  header={t("navigation.dashboard")}
                  showButton={false}
                />
              }
              selectionMode="single"
              selection={selectedWorkOverview}
              onSelectionChange={(e) =>
                setSelectedWorkOverview(
                  e.value as DashboardOverviewItem | undefined
                )
              }
              emptyMessage={t("common.noResultsFound")}
            >
              <Column
                field="username"
                header={t("common.username")}
                sortable
                showFilterMenu={false}
              />
              <Column
                field="userPhone"
                header={t("common.phone")}
                sortable
                showFilterMenu={false}
              />
              <Column
                field="installationName"
                header={t("common.installation")}
                sortable
                showFilterMenu={false}
              />
              <Column
                field="dateStarted"
                header={t("common.startDate")}
                sortable
                body={(x) => format(x.dateStarted, "dd/LL/yyyy HH:mm")}
                showFilterMenu={false}
              />
              <Column
                field="workState"
                body={(x) => <div>{getCurrentDurationString(x)}</div>}
                header={t("common.workState")}
                sortable
                showFilterMenu={false}
              />
              <Column
                field="lastLocation.date"
                header={t("common.lastActivity")}
                body={lastActivityBodyItemTemplate}
                sortable
              />
            </DataTable>
          </LoaderWrapper>
        </RoundedShadowContainer>
      </div>
      <div className="w-6">
        <RoundedShadowContainer
          small
          fullHeight
        >
          <div className="relative h-full">
            <MapWithMarkers
              mapBorderRadius="0.75rem"
              showInfoWindows={true}
              markers={mapMarkers}
              currenPosition={hoveredPosition}
              onPositionHover={setHoveredPosition}
              strokes={
                dashboardDetailQuery.data?.workInstance.locationLogs ?? []
              }
            />
            {selectedWorkOverview &&
              dashboardDetailQuery.data?.workInstance.locationLogs && (
                <div
                  style={{ bottom: "0" }}
                  className="absolute border-round-xl w-full z-5 blur-container h-12rem pt-2"
                >
                  <SelectedWorkDetails
                    className="h-4rem"
                    data={dashboardDetailQuery.data}
                    onFinish={showFinishWorkModal}
                  />
                  <WorkTimeChartComponent
                    className="h-8rem"
                    workInstance={dashboardDetailQuery.data?.workInstance}
                    onHover={setHoveredPosition}
                    currentPosition={hoveredPosition}
                  />
                </div>
              )}
          </div>
        </RoundedShadowContainer>
      </div>
    </div>
  );
}
