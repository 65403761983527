import {
  Field,
  ErrorMessage,
  FastField,
  FormikContextType,
  FieldInputProps,
  FieldMetaProps,
} from "formik";
import TextError from "./TextError";
import { FormInputLabel } from "./FormInputLabel";
import { useMemo } from "react";
import { SelectItem } from "primereact/selectitem";
import * as Yup from "yup";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useTranslation } from "react-i18next";

export interface FormikInlineMultiSelectPros {
  label: string;
  name: string;
  className?: string;
  showFilter?: boolean;
  options?: SelectItem[];
  validationSchema?: Yup.ObjectSchema<any, Yup.AnyObject, any, "">;
  isIndependent?: boolean;
  isRequired?: boolean;
  disabled?: boolean;
}

function FormikInlineMultiSelect({
  label,
  name,
  showFilter = false,
  className = "",
  options = [],
  validationSchema,
  isIndependent = false,
  isRequired = undefined,
  disabled = false,
  ...rest
}: FormikInlineMultiSelectPros) {
  const { t } = useTranslation();

  const Component = useMemo(() => {
    return isIndependent ? FastField : Field;
  }, [isIndependent]);
  return (
    <>
      <Component
        name={name}
        className="h-full"
      >
        {({
          form,
          field,
          meta,
        }: {
          form: FormikContextType<any>;
          field: FieldInputProps<any>;
          meta: FieldMetaProps<any>;
        }) => {
          return (
            <div className="h-full border-300 surface-overlay border-1 border-round-xl ">
              <DataTable
                value={options}
                selection={options.filter((o) =>
                  field.value?.includes(o.value)
                )}
                className={className}
                onSelectionChange={(e) => {
                  e.originalEvent.stopPropagation();
                  form.setFieldValue(
                    name,
                    (e.value as []).map((v) => (v as any).value)
                  );
                }}
                disabled={disabled}
                dataKey="value"
                scrollable={true}
                scrollHeight="flex"
                showHeaders={false}
                header={
                  <FormInputLabel
                    nameFor={name}
                    validationSchema={validationSchema}
                    forceIsRequired={isRequired}
                  >
                    {`${label} ${
                      field.value?.length ? `(${field.value.length})` : ""
                    }`}
                  </FormInputLabel>
                }
                emptyMessage={t("common.noResultsFound")}
                {...rest}
              >
                <Column
                  selectionMode="multiple"
                  headerStyle={{ width: "3rem" }}
                ></Column>
                <Column field="label" />
              </DataTable>
            </div>
          );
        }}
      </Component>
      <ErrorMessage
        component={TextError}
        name={name}
      />
    </>
  );
}

export default FormikInlineMultiSelect;
