import { TaskState } from "../../../queries/models/enums/task-state.enum";
import { useInstallationWorkTasksQuery } from "../../../queries/work-tasks.query";
import { useMemo, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { LoaderWrapper } from "../../../components/ui/LoaderWrapper";
import format from "date-fns/format";
import { Button } from "primereact/button";
import { WorkTask } from "../../../queries/models/work-task.model";
import { CustomModal } from "../../../components/ui/MobileModal/custom-modal";
import { useActiveUsersAsSelectOptionQuery } from "../../../queries/users.query";
import { useWindowSize } from "../../../hooks/use-window-size";
import { useTranslation } from "react-i18next";

function InstallationTasksTableHeader() {
  const { t } = useTranslation();

  return (
    <div className="flex justify-content-between align-items-center">
      <div className="text-lg">Work tasks</div>
      <div
        className="text-blue-600 underline cursor-pointer"
        onClick={() => window.open("/app/tasks-board", "_blank")}
      >
        {t("common.goToBoard")}
      </div>
    </div>
  );
}

function WorkTaskDetails({
  workTask,
  userNames,
}: {
  workTask: WorkTask;
  userNames: string[];
}) {
  const { t } = useTranslation();

  return (
    <>
      <div className="grid m-0">
        <div className="col-6 text-right text-500">{t("common.id")}:</div>
        <div className="col-6">{workTask.id}</div>
        <div className="col-6 text-right text-500">{t("common.name")}:</div>
        <div className="col-6">{workTask.name}</div>
        <div className="col-6 text-right text-500">{t("common.state")}:</div>
        <div className="col-6">{TaskState[workTask.state]}</div>
        {workTask.dueDate && (
          <>
            <div className="col-6 text-right text-500">
              {t("common.dueDate")}:
            </div>
            <div className="col-6">
              {format(new Date(workTask.dueDate), "dd/LL/yyyy")}
            </div>
          </>
        )}
        <div className="col-6 text-right text-500">
          {t("common.description")}:
        </div>
        <div className="col-6">{workTask.description}</div>
        {userNames.length !== 0 && (
          <>
            <div className="col-6 text-right text-500">
              {t("common.assignedUsers")}:
            </div>
            <div className="col-6">{userNames.join(", ")}</div>
          </>
        )}
      </div>
    </>
  );
}

interface InstallationTasksProps {
  installationId: number;
}

export function InstallationTasks({ installationId }: InstallationTasksProps) {
  const { t } = useTranslation();
  const { lg } = useWindowSize();
  const tasksQuery = useInstallationWorkTasksQuery(installationId);
  const usersQuery = useActiveUsersAsSelectOptionQuery();
  const [selectedWorkTask, setSelectedWorkTask] = useState<
    WorkTask | undefined
  >(undefined);

  const selectedWorkTaskUserNames = useMemo(() => {
    return (
      usersQuery.data
        ?.filter((x) => selectedWorkTask?.assignedUserIds?.includes(x.value))
        ?.map((x) => x.label ?? "") ?? []
    );
  }, [selectedWorkTask, usersQuery.data]);

  const isLoading =
    tasksQuery.isLoading ||
    tasksQuery.isFetching ||
    usersQuery.isLoading ||
    usersQuery.isFetching;

  const sortedTasks = useMemo(() => {
    return tasksQuery.data?.sort((a, b) => {
      if (a.state === TaskState.Doing && b.state !== TaskState.Doing) {
        return -1;
      } else if (a.state !== TaskState.Doing && b.state === TaskState.Doing) {
        return 1;
      }
      if (a.dueDate && b.dueDate) {
        if (+a.dueDate > +b.dueDate) {
          return 1;
        } else if (+a.dueDate < +b.dueDate) {
          return -1;
        }
      } else if (a.dueDate) {
        return -1;
      } else if (b.dueDate) {
        return 1;
      }
      return 0;
    });
  }, [tasksQuery.data]);

  return (
    <div className="h-full">
      <LoaderWrapper isLoading={isLoading}>
        <DataTable
          header={<InstallationTasksTableHeader />}
          value={sortedTasks}
          scrollable
          scrollHeight="flex"
          emptyMessage={t("common.noResultsFound")}
        >
          <Column
            field="name"
            header={t("common.name")}
            sortable
          />
          <Column
            field="state"
            header={t("common.state")}
            body={(x) => TaskState[x.state]}
            sortable
          />
          <Column
            field="dueDate"
            header={t("common.dueDate")}
            body={(x) =>
              x.dueDate ? format(new Date(x.dueDate), "dd/LL/yyyy") : ""
            }
            sortable
            dataType="date"
          />
          <Column
            header={t("common.action")}
            body={(x) => (
              <Button
                label={t("common.details")}
                onClick={() => setSelectedWorkTask(x as WorkTask)}
              />
            )}
          />
        </DataTable>
      </LoaderWrapper>
      <CustomModal
        isOpen={!!selectedWorkTask}
        header={t("common.workTaskDetails")}
        onClose={() => setSelectedWorkTask(undefined)}
        justified
        centered
        height="max-content"
        width={lg ? "50%" : "95%"}
        body={
          <WorkTaskDetails
            workTask={selectedWorkTask!}
            userNames={selectedWorkTaskUserNames}
          />
        }
      />
    </div>
  );
}
