import { useCallback, useMemo } from "react";
import { Asset } from "../../../queries/models/asset.model";
import { LoaderWrapper } from "../LoaderWrapper";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useTranslation } from "react-i18next";
import { TagSpan } from "../tags/tag-span";
import { format } from "date-fns";
import { useAssetTypesQuery } from "../../../queries/asset-type.query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Tooltip } from "primereact/tooltip";

interface AssetListProps {
  assets?: Asset[] | undefined;
  isLoading?: boolean;
}

export function AssetList({ assets = [], isLoading = false }: AssetListProps) {
  const { t } = useTranslation();
  const assetTypesQuery = useAssetTypesQuery();

  const sortedAssets = useMemo(() => {
    return assets.sort((a, b) => +b.installationDate! - +a.installationDate!);
  }, [assets]);

  const assetTypeIconBodyTemplate = useCallback(
    (x: Asset) => {
      const assetType = assetTypesQuery.data?.find(
        (at) => at.id === x.assetTypeId
      );

      return (
        <>
          <Tooltip target=".icon" />
          <div
            className="icon"
            data-pr-tooltip={
              assetTypesQuery?.data?.find((at) => at.id === x.assetTypeId)?.name
            }
          >
            <FontAwesomeIcon
              icon={assetType?.icon as IconProp}
              color={assetType?.backgroundColor}
              size="xl"
            />
          </div>
        </>
      );
    },
    [assetTypesQuery.data]
  );

  return (
    <div className="w-full h-full">
      <LoaderWrapper isLoading={isLoading}>
        <DataTable
          value={sortedAssets}
          scrollable
          scrollHeight="flex"
          emptyMessage={t("common.noResultsFound")}
        >
          <Column
            header={t("common.name")}
            body={(x: Asset) => (
              <>
                <a
                  href={`/app/assets/details/${x.id}`}
                  target="_blank"
                  rel="noreferrer"
                  className="font-semibold no-underline text-color"
                >
                  {x.name}
                </a>
                <div className="font-italic">{x.description}</div>
              </>
            )}
          />
          <Column
            header={t("common.type")}
            body={assetTypeIconBodyTemplate}
          />
          <Column
            header={t("common.tag")}
            body={(x: Asset) => (
              <>
                {x.tags.map((t) => (
                  <TagSpan
                    key={t.id}
                    tag={t}
                  />
                ))}
              </>
            )}
          />
          <Column
            header={t("common.maintenance")}
            body={(x: Asset) => (
              <>
                <div>{format(new Date(x.installationDate!), "dd/MM/yyyy")}</div>
                {x.nextFollowupDate && (
                  <div className="text-red-700">
                    {format(new Date(x.nextFollowupDate), "dd/MM/yyyy")}
                  </div>
                )}
              </>
            )}
          />
        </DataTable>
      </LoaderWrapper>
    </div>
  );
}
