import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "../../../hooks/use-window-size";
import { Installation } from "../../../queries/models/installation.model";
import { InstallationForm } from "../InstallationsForm";
import { AddOrEditInstallation } from "../../../queries/models/form-installation.model";
import { useToast } from "../../../components/ui/toast-context-provider";
import { useQueryClient } from "react-query";
import { EditInstallationRequest } from "../../../queries/models/edit-installation-request";
import { CreateInstallationRequest } from "../../../queries/models/create-installation-request.model";
import { useEditInstallationMutation } from "../../../queries/installations.query";
import { useAddInstallationMutation } from "../../../queries/installations.query";
import { useDeleteInstallationMutation } from "../../../queries/installations.query";
import { RoundedShadowContainer } from "../../../components/ui/rounded-shadow-container";
import { TabView, TabPanel } from "primereact/tabview";
import {
  CustomModal,
  CustomModalProps,
} from "../../../components/ui/MobileModal/custom-modal";
import { Note } from "../../../components/ui/Note/Note";
import {
  useAddNoteMutation,
  useGetNoteQuery,
} from "../../../queries/notes.query";
import { AddNoteRequest } from "../../../queries/models/add-note-request";
import {
  CalendarBodyProps,
  CalendarComponent,
} from "../../../components/ui/calendar/CalendarComponent";
import { isSameDay } from "date-fns";
import { AssetList } from "../../../components/ui/Assets/AssetList";
import { useGetAllInstallationAssetsQuery } from "../../../queries/assets.query";
import { useGetInstallationQuery } from "../../../queries/installations.query";
import { useParams } from "react-router-dom";
import { InstallationTasks } from "./InstallationTasks";
import { NoteSeverity } from "../../../queries/models/enums/note-severity";
import { EmptyContentBanner } from "../../../components/ui/EmptyContentBanner";
import { InstallationFloorPlans } from "./InstallationPlans/InstallationFloorPlans";
import { CalendarEntry } from "../../../components/ui/calendar/calendar-entry";
import { LoaderWrapper } from "../../../components/ui/LoaderWrapper";
import { authService } from "../../../services/auth.service";
import { AppFeatures } from "../../../queries/models/enums/app-feature-enum";
import { useTranslation } from "react-i18next";
import { InstallationWorkInstances } from "./InstallationWorkInstances";
import { useInstallationValidatedWorkInstancesQuery } from "../../../queries/work-instances.query";
import { InputSwitch } from "primereact/inputswitch";

const calendarBodyOptions: CalendarBodyProps = {
  calendarDayStyle: "text-black-800 text-l text-right font-medium",
  showUserOnly: true,
};

export function InstallationDetails() {
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState<boolean>(false);
  const params = useParams();
  const id = useMemo(
    () => (params?.id ? Number(params.id) : undefined),
    [params]
  );
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();
  const queryClient = useQueryClient();
  const installationsQuery = useGetInstallationQuery(id);
  const addInstallationMutation = useAddInstallationMutation();
  const editInstallationMutation = useEditInstallationMutation();
  const deleteInstallationMutation = useDeleteInstallationMutation();
  const addNoteMutation = useAddNoteMutation();
  const [selectedInstallation, setSelectedInstallation] = useState<
    Installation | undefined
  >({ name: "" } as Installation);
  const [selectedDays, setSelectedDays] = useState<Date[]>([]);
  const noteQuery = useGetNoteQuery(selectedInstallation?.noteId);
  const workInstancesQuery = useInstallationValidatedWorkInstancesQuery(id);
  const assetsQuery = useGetAllInstallationAssetsQuery(id);
  const hasWriteAccess = useMemo(
    () => authService.hasAccess(AppFeatures.WebInstallationsWrite),
    []
  );

  const [showNotesOnly, setShowNotesOnly] = useState(false);

  useEffect(() => {
    if (installationsQuery.data)
      setSelectedInstallation(installationsQuery.data);
  }, [installationsQuery]);

  const noteItems = useMemo(() => {
    return selectedDays.length > 0
      ? noteQuery.data?.items?.filter((x) =>
          selectedDays.some((y) => isSameDay(y, new Date(x.date)))
        ) ?? []
      : noteQuery.data?.items ?? [];
  }, [noteQuery.data, selectedDays]);

  const workInstanceItems = useMemo(() => {
    return selectedDays.length > 0
      ? workInstancesQuery.data?.filter(
          (x) =>
            (x.dateStarted || x.datePlanned) &&
            selectedDays.some((y) =>
              isSameDay(y, new Date(x.dateStarted ?? x.datePlanned!))
            )
        )
      : workInstancesQuery.data;
  }, [selectedDays, workInstancesQuery.data]);

  const { lg } = useWindowSize() || {};

  const handleDeleteInstallation = useCallback(() => {
    if (selectedInstallation?.id) {
      return deleteInstallationMutation.mutateAsync(selectedInstallation.id, {
        onSuccess: async () => {
          await queryClient.invalidateQueries("installations");
          toast.current?.show({
            severity: "success",
            detail: t("common.installationSuccessfullyDeleted"),
          });
          setSelectedInstallation(undefined);
          navigate(-1);
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
      });
    }
  }, [
    deleteInstallationMutation,
    navigate,
    queryClient,
    selectedInstallation?.id,
    t,
    toast,
  ]);

  const editExistingInstallation = useCallback(
    (e: AddOrEditInstallation) => {
      const mutateOptions = {
        onSuccess: async () => {
          await queryClient.invalidateQueries("installations");
          toast.current?.show({
            severity: "success",
            detail: t("common.editSuccess"),
          });
          setSelectedInstallation(undefined);
          navigate(-1);
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
      };

      const request: EditInstallationRequest = {
        id: e.id,
        name: e.name,
        contactName: e.contactName,
        contactPhone: e.contactPhone,
        contactEmail: e.contactEmail,
        address: {
          radius: e.address.radius,
          id: e.address.id,
          countryId: e.address.countryId,
          city: e.address.city,
          street: e.address.street,
          postalCode: e.address.postalCode,
          latitude: e.address.latitude,
          longitude: e.address.longitude,
        },
        assignedUserIds: e.assignedUserIds,
      };

      return editInstallationMutation.mutateAsync(request, mutateOptions);
    },
    [editInstallationMutation, navigate, queryClient, t, toast]
  );

  const saveNewInstallation = useCallback(
    (e: AddOrEditInstallation) => {
      const mutateOptions = {
        onSuccess: async () => {
          await queryClient.invalidateQueries("notes");
          toast.current?.show({
            severity: "success",
            detail: t("common.saveSuccess"),
          });
          setSelectedInstallation(undefined);
          navigate(-1);
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
      };

      const request: CreateInstallationRequest = {
        name: e.name,
        contactName: e.contactName,
        contactPhone: e.contactPhone,
        contactEmail: e.contactEmail,
        address: {
          radius: e.address.radius,
          countryId: e.address.countryId,
          city: e.address.city,
          street: e.address.street,
          postalCode: e.address.postalCode,
          latitude: e.address.latitude ?? 0,
          longitude: e.address.longitude ?? 0,
        },
        assignedUserIds: e.assignedUserIds,
      };

      return addInstallationMutation.mutateAsync(request, mutateOptions);
    },
    [addInstallationMutation, navigate, queryClient, t, toast]
  );

  const handleSaveInstallation = useCallback(
    (addOrEditInstallation: AddOrEditInstallation) => {
      return addOrEditInstallation.id !== 0
        ? editExistingInstallation(addOrEditInstallation)
        : saveNewInstallation(addOrEditInstallation);
    },
    [editExistingInstallation, saveNewInstallation]
  );

  const deleteConfirmationModalProps: CustomModalProps = useMemo(() => {
    return {
      header: t("common.confirmation"),
      body: t("dialog.deleteThisInstallation"),
      height: "160px",
      isOpen: isDeleteConfirmationModalOpen,
      confirmation: true,
      centered: true,
      justified: true,
      onClose: () => setIsDeleteConfirmationModalOpen(false),
      onConfirm: handleDeleteInstallation,
    };
  }, [handleDeleteInstallation, isDeleteConfirmationModalOpen, t]);

  const createNewNote = useCallback(
    (data: AddNoteRequest) => {
      const mutateOptions = {
        onSuccess: async () => {
          toast.current?.show({
            severity: "success",
            detail: t("common.saveSuccess"),
          });
          await queryClient.invalidateQueries("notes");
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: t("common.uploadFailed"),
          });
        },
      };

      const request: AddNoteRequest = {
        noteId: selectedInstallation?.noteId ?? 0,
        message: data.message!,
        severity: data.severity,
        isConfidential: data.isConfidential,
        files: data.files,
      };

      return addNoteMutation.mutateAsync(request, mutateOptions);
    },
    [addNoteMutation, queryClient, selectedInstallation?.noteId, t, toast]
  );

  const noteCalendarItems = useMemo(() => {
    return noteQuery.data?.items
      ?.filter((x) => x.severity !== NoteSeverity.SystemLog)
      .map(
        (x) =>
          (({ date: x.date, subtitle: x.user?.username } as CalendarEntry) ??
          [])
      );
  }, [noteQuery.data?.items]);

  const workInstanceCalendarItems = useMemo(() => {
    return workInstancesQuery.data?.map(
      (x) =>
        (({
          date: x.dateStarted ?? x.datePlanned,
          subtitle: x.user?.username,
        } as CalendarEntry) ?? [])
    );
  }, [workInstancesQuery.data]);

  const calendarItems = useMemo(() => {
    if (showNotesOnly) {
      return noteCalendarItems;
    } else return workInstanceCalendarItems;
  }, [noteCalendarItems, showNotesOnly, workInstanceCalendarItems]);

  const additionalCalendarMenuItems = useMemo(() => {
    return [
      {
        template: (
          <div className="px-3 flex gap-2">
            <InputSwitch
              checked={showNotesOnly}
              onChange={(e) => setShowNotesOnly(e.value ?? false)}
            />
            <>{t("common.showNotesOnly")}</>
          </div>
        ),
      },
    ];
  }, [showNotesOnly, t]);

  return (
    <div className="h-full flex">
      <CustomModal {...deleteConfirmationModalProps} />
      <div className={lg ? "w-6 p-1 h-full " : "col-12 p-0"}>
        {selectedInstallation && (
          <InstallationForm
            installation={selectedInstallation}
            onSave={handleSaveInstallation}
            onCancel={() => navigate(-1)}
            onDelete={() => setIsDeleteConfirmationModalOpen(true)}
          />
        )}
      </div>
      <div className={lg ? "w-6 p-1 h-full" : "col-12"}>
        {selectedInstallation && (
          <div className="h-full w-full">
            <div
              className="w-12"
              style={{
                height: "calc(50% - 5px)",
                marginBottom: "5px",
              }}
            >
              <RoundedShadowContainer
                small
                fullHeight
              >
                <LoaderWrapper
                  isLoading={
                    noteQuery.isLoading || workInstancesQuery.isLoading
                  }
                  containerClassName="w-full h-full overflow-auto pb-5"
                >
                  <CalendarComponent
                    bodyOptions={calendarBodyOptions}
                    onDatesSelected={setSelectedDays}
                    entries={calendarItems}
                    groupEntriesByName={true}
                    additionalMenuItems={additionalCalendarMenuItems}
                  />
                </LoaderWrapper>
              </RoundedShadowContainer>
            </div>
            <div
              className="w-12"
              style={{ height: "calc(50%)", marginTop: "5px" }}
            >
              <RoundedShadowContainer
                small
                fullHeight
              >
                <TabView className="h-full">
                  <TabPanel
                    header={t("common.notes")}
                    className="h-full"
                  >
                    {id ? (
                      <Note
                        isLoading={noteQuery.isLoading}
                        items={noteItems}
                        onAddNote={createNewNote}
                        hasWriteAccess={hasWriteAccess}
                      />
                    ) : (
                      <EmptyContentBanner
                        text={t("common.SAVE_EMPTY_INSTALLATION_FIRST")}
                      />
                    )}
                  </TabPanel>
                  <TabPanel
                    header={t("common.workInstances")}
                    className="h-full"
                  >
                    {id ? (
                      <InstallationWorkInstances
                        isLoading={
                          workInstancesQuery.isLoading ||
                          workInstancesQuery.isFetching
                        }
                        items={workInstanceItems ?? []}
                      />
                    ) : (
                      <EmptyContentBanner
                        text={t("common.SAVE_EMPTY_INSTALLATION_FIRST")}
                      />
                    )}
                  </TabPanel>
                  <TabPanel
                    header={t("common.assets")}
                    className="h-full"
                  >
                    {id ? (
                      <AssetList
                        assets={assetsQuery.data}
                        isLoading={assetsQuery.isLoading}
                      />
                    ) : (
                      <EmptyContentBanner
                        text={t("common.SAVE_EMPTY_INSTALLATION_FIRST")}
                      />
                    )}
                  </TabPanel>
                  <TabPanel
                    header={t("common.tasks")}
                    className="h-full"
                  >
                    {id ? (
                      <InstallationTasks installationId={id} />
                    ) : (
                      <EmptyContentBanner
                        text={t("common.SAVE_EMPTY_INSTALLATION_FIRST")}
                      />
                    )}
                  </TabPanel>
                  <TabPanel
                    header={t("common.buildingPlan")}
                    className="h-full"
                  >
                    {id ? (
                      <InstallationFloorPlans
                        buildingPlanId={selectedInstallation.buildingPlanId}
                        installationId={id}
                        hasWriteAccess={hasWriteAccess}
                      />
                    ) : (
                      <EmptyContentBanner
                        text={t("common.SAVE_EMPTY_INSTALLATION_FIRST")}
                      />
                    )}
                  </TabPanel>
                </TabView>
              </RoundedShadowContainer>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
