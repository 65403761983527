import { useCallback, useState, BaseSyntheticEvent } from "react";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { DashboardOverviewItem } from "../../../queries/models/dashboard-overview-item.model";
import { TableHeaderWithFilter } from "../../../components/ui/table-header-with-filter";
import { useTranslation } from "react-i18next";

interface MobileDashboardItemsOverviewProps {
  selectedOverviewItem: DashboardOverviewItem | undefined;
  setSelectedOverviewItem: (e: DashboardOverviewItem) => void;
  items: DashboardOverviewItem[] | undefined;
}

export function MobileDashboardItemsOverview({
  items,
  selectedOverviewItem,
  setSelectedOverviewItem,
}: MobileDashboardItemsOverviewProps) {
  const { t } = useTranslation();
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const onGlobalFilterChange = useCallback(
    (e: BaseSyntheticEvent) => {
      let _filters = { ...filters };

      const value = e.target.value;
      _filters["global"].value = value;
      setFilters(filters);
      setGlobalFilterValue(value);
    },
    [filters]
  );
  return (
    <>
      <DataTable
        value={items}
        className="mobile-table"
        filters={filters}
        globalFilterFields={["username", "installationName"]}
        header={
          <TableHeaderWithFilter
            header="Dashboard"
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
          />
        }
        scrollable={true}
        scrollHeight="flex"
        selectionMode="single"
        selection={selectedOverviewItem}
        onSelectionChange={(e) =>
          setSelectedOverviewItem(e.value as DashboardOverviewItem)
        }
        emptyMessage={t("common.noResultsFound")}
      >
        <Column
          header="Username"
          field="username"
        />
        <Column
          header="Installation"
          field="installationName"
        />
      </DataTable>
    </>
  );
}
