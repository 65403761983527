import { useCallback, useState } from "react";
import { Button } from "primereact/button";
import { useWindowSize } from "../../hooks/use-window-size";
import { classNames } from "primereact/utils";
import { useTranslation } from "react-i18next";

export interface EditContentHeaderProps {
  header: string;
  showDeleteButton: boolean;
  saveButtonDisabled: boolean;
  deleteButtonDisabled?: boolean;
  extraBtn1?: React.ReactNode;
  onSaveClick: () => Promise<any> | void;
  onCancelClick: () => Promise<any> | void;
  onDeleteClick?: () => Promise<any> | void;
}

export function EditContentHeader({
  header,
  showDeleteButton,
  saveButtonDisabled,
  deleteButtonDisabled = false,
  extraBtn1,
  onSaveClick,
  onCancelClick,
  onDeleteClick,
}: EditContentHeaderProps) {
  const { t } = useTranslation();
  const { lg } = useWindowSize();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSave = useCallback(() => {
    setIsSubmitting(true);
    Promise.resolve(onSaveClick()).finally(() => setIsSubmitting(false));
  }, [onSaveClick]);

  const onCancel = useCallback(() => {
    setIsSubmitting(true);
    Promise.resolve(onCancelClick()).finally(() => setIsSubmitting(false));
  }, [onCancelClick]);

  const onDelete = useCallback(() => {
    setIsSubmitting(true);
    if (onDeleteClick) {
      Promise.resolve(onDeleteClick()).finally(() => setIsSubmitting(false));
    }
  }, [onDeleteClick]);

  return (
    <div className="flex justify-content-between">
      {lg && <div className="text-xl font-bold">{header}</div>}
      <div
        className={classNames(
          "flex",
          !lg ? "w-full justify-content-between" : ""
        )}
      >
        {extraBtn1}
        <Button
          icon="pi pi-save"
          type="button"
          label={t("common.save")}
          loading={isSubmitting}
          className="green-action-button min-w-max mr-1 w-full"
          onClick={onSave}
          disabled={saveButtonDisabled}
        />
        {showDeleteButton && (
          <Button
            icon="pi pi-trash"
            type="button"
            label={t("common.delete")}
            loading={isSubmitting}
            className="delete-button min-w-max mr-1 w-full"
            onClick={onDelete}
            disabled={deleteButtonDisabled}
          />
        )}
        <Button
          icon="pi pi-times"
          type="button"
          label={t("common.cancel")}
          className="yellow-button min-w-max mr-1 w-full"
          onClick={onCancel}
        />
      </div>
    </div>
  );
}
